<template>
  <div
    class="card-paycoin"
    :class="active ? 'active' : disabled ? 'disabled' : 'received'"
  >
    <div class="left">
      <img src="../../assets/profile_assets/paycoin-arrow.svg" alt="" />
    </div>
    <div class="right">
      <div class="top">
        <p>
          <span>{{ bonus }}</span> Paycoin
        </p>
        <span>
          <img src="../../assets/profile_assets/paycoin-coins.svg" alt="" />
        </span>
      </div>
      <div class="bottom">
        <p><span>+3%</span> {{ localize('profile')['forSale'] }}</p>
        <span>
          <a href="javascript:">{{ localize('button')['upgrade'] }}</a>
          <img src="../../assets/profile_assets/paycoin-sale.svg" alt="" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import localize from '../../filters/localize.filter';

export default {
  name: "PaycoinCard",
  props: {
    received: Boolean,
    active: Boolean,
    disabled: Boolean,
    bonus: Number,
  },
  methods: {
    localize,
  },
};
</script>

<style lang="scss" scoped>
.card-paycoin {
  background: $white;
  border-radius: 12px;
  padding: 15px 12px;
  display: flex;

  &.active {
    border: 1px solid #6610F5;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .right {
    width: 100%;

    .top {
      border-bottom: 1px solid #e8e8e8;
    }

    .top,
    .bottom {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 136%;
        display: flex;
        align-items: center;
        color:  $black;

        span {
          color: $main;
          margin-right: 8px;
        }
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          font-size: 16px;
          line-height: 136%;
          display: flex;
          align-items: center;
          color: $main;
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
