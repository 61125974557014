<template>
  <div class="sale">
    <section>
      <div class="container fluid">
        <div class="row aic">
          <div class="col-auto">
            <ul class="breadcumb">
              <li>
                <router-link :to="{ name: 'profile-index' }">
                  {{ localize('breadcrumb')['home'] }}
                </router-link>
              </li>
              <img src="../../assets/profile_assets/chevron-right.svg" alt="" />
              <li>
                <router-link class="active" :to="{ name: 'profile.faq' }">
                  {{ localize('profile')['sales'] }}
                </router-link>
              </li>
            </ul>
          </div>

          <div class="col-auto">
            <router-link :to="{ name: 'profile-index' }" tag="button" class="btn-back">
              {{ localize('button')['back'] }}
            </router-link>
          </div>
        </div>

        <h2>{{ localize('profile')['sales'] }}</h2>
        <div class="row">
          <div class="col-xl-5 col-ml-5 col-lg-6 col-md-8 col-sm-12">
            <div class="bunus_card">

              <div class="left">
                <p>{{ localize('profile')['bonusPoints'] }}</p>
                <h3>{{ paycoin.paycoin }} Paycoin</h3>
              </div>

              <div class="right">
                <router-link :to="{ name: 'profile.faq' }">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.01 10.4568C10.01 9.35777 10.901 8.46677 12 8.46677C13.099 8.46677 13.99 9.35777 13.99 10.4568C13.99 11.3478 13.441 12.0088 12.597 12.3558C12.209 12.5148 12 12.8558 12 12.8558M11.9982 15.5332L12 15.5302M19.998 11.999C19.998 16.4161 16.4171 19.997 12 19.997C7.58278 19.997 4.00195 16.4161 4.00195 11.999C4.00195 7.5818 7.58278 4.00098 12 4.00098C16.4171 4.00098 19.998 7.5818 19.998 11.999Z"
                      stroke="$white"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </router-link>
              </div>

            </div>
          </div>
        </div>

        <h3 class="spend-pay-coin-title">
          {{ localize('profile')['PaycoinSalePage.vue'] }}
        </h3>

        <div class="row">
          <div class="col-xl-4 col-lg-4" v-for="(n, i) in item" :key="i">
            <PaycoinSale class="spend-pay-coin" v-bind="n" />
          </div>
        </div>

        <div>
          <button class="p-button">
            {{ localize('button')['upgrade'] }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PaycoinSale from '../../components/profile/PaycoinCard.vue';
import paycoin from '../../data/paycoin';
import localize from '../../filters/localize.filter';

export default {
  data: () => ({ paycoin, item: [] }),
  components: { PaycoinSale },
  methods: {
    localize,
    sale() {
      paycoin.bonuce.map((n) => {
        if (n.type == 'PaycoinSalePage.vue') {
          this.item = n.item;
          n.item.map((l) => {
            if (l.received) {
              l.active = false;
              l.disabled = true;
            } else if (l.active) {
              l.disabled = false;
              l.received = false;
            }
          });
          console.log(this.item);
        }
      });
    },
  },
  mounted() {
    this.sale();
  },
};
</script>

<style lang="scss" scoped>
  .sale {
    padding: 48px 44px;
    min-height: calc(100vh - 96px);
    background: $grey;
    width: 100%;

    .spend-pay-coin-title {
      margin-bottom: 1rem;
    }

    h2 {
      font-weight: 900;
      font-size: 32px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: $black;
      margin-top: 16px;
      margin-bottom: 32px;
    }

    .bunus_card {
      background: url("../../assets/profile_assets/bonusCardBg.svg") no-repeat center center / cover;
      border-radius: 8px;
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 32px;

      p {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: $white;
        margin-bottom: 8px;
      }

      h3 {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.326451px;
        color: $white;
      }
    }
  }

  button.p-button {
    cursor: pointer;
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 14px;
    width: auto;
    padding: 18px 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: 0.2s;
    border: none;
    margin-top: 40px;

    &:hover {
      background: $main-light;
    }
  }

  .aic {
    align-items: center;
    justify-content: space-between;
  }

  .btn-back {
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 8px;
    width: auto;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border: none;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      background: $main-light;
    }
  }

  .breadcumb {
    display: flex;
    align-items: center;

    li {
      margin-bottom: 0px !important;
    }

    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: $black;
      opacity: 0.3;

      &.active {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: $main;
        opacity: 1;
      }
    }
  }

  @media (max-width: 575px) {
    .sale {
      padding-right: 0;
      padding-left: 0;

      .p-button {
        width: 100%;
        margin-top: 20px;
      }

      .spend-pay-coin-title {
        margin-bottom: 0.5rem;
      }

      .spend-pay-coin {
        margin-bottom: 0.5rem;
      }
    }
  }
</style>
